export type PlcCustomATInfo = {
  tagPageNameI18nArray: (string | undefined | { code: string; notI18n: boolean })[];
  level2: ATLevel2;
};

export type PlcTagProps = PlcCustomATInfo & { isDataLoading?: boolean };

export enum ATLevel2 {
  Journal = 1,
  Meteo = 2,
  Stocks = 3,
  Services = 4,
  Search = 5,
  Actu = 6,
  Events = 7,
  Guides = 8,
  SignUp = 9,
  Footer = 10,
  Partners = 11,
  SignIn = 12,
  Ads = 13,
  UserAccount = 14,
}
