export const fadeInScaleY = {
  displayed: { opacity: 1, scaleY: 1 },
  hidden: { opacity: 0, scaleY: 0 },
};

export const fadeInHeight = {
  displayed: { height: '100%', opacity: 1, transitionEnd: { overflow: '' } },
  hidden: { height: '0', opacity: 0 },
};

export const fadeIn = {
  displayed: { opacity: 1, transitionEnd: { overflow: '' } },
  hidden: { opacity: 0 },
};
