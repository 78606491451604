import React, { ComponentProps, PropsWithChildren, useRef } from 'react';
import { FadeInElement } from '@components/animations/FadeInElement/FadeInElement.component';
import { PlcImgLoader } from '@components/Loaders/PlcImgLoader.component';
import { PlcImg } from '@components/PlcImg/PlcImg.component';
import { PlcImgProps } from '@components/PlcImg/PlcImg.types';
import { Text } from '@components/Text/Text.component';
import { useLockBodyScroll } from '@layout/business/LockBodyScroll.hook';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { ClippedImageStyle } from '@utils/images';
import classnames from 'classnames';

export type ClippedImageProps = PlcImgProps & {
  clipStyle: ClippedImageStyle;
  caption?: string;
};

function ClippedImage({ caption, clipStyle, onLoad, className, ...rest }: PropsWithChildren<ClippedImageProps>) {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isFigcaptionOpen, setFigcaptionOpen] = React.useState(false);
  const rootClassName = classnames('clipped-img', clipStyle, className);
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  const figcaptionRef = useRef<HTMLElement>(null);
  useLockBodyScroll(isMobile && isFigcaptionOpen, figcaptionRef.current);
  return (
    <figure className={rootClassName}>
      <PlcImg
        container={(children: React.ReactNode) => {
          return (
            <FadeInElement
              className="container"
              forceMount
              onClick={() => (caption && isMobile ? setFigcaptionOpen(true) : null)}
              show={isLoaded}
            >
              {children}
            </FadeInElement>
          );
        }}
        onLoad={() => {
          setIsLoaded(true);
        }}
        {...rest}
      />

      {caption && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <figcaption
          ref={figcaptionRef}
          className={isMobile && isFigcaptionOpen ? 'open' : 'closed'}
          onClick={() => setFigcaptionOpen(false)}
        >
          <Text flavour="grey" variant="tiny">
            {caption}
          </Text>
        </figcaption>
      )}
    </figure>
  );
}

function ClippedImageLoader({
  clipStyle,
  className,
  uniqueKey,
  ...rest
}: ComponentProps<'figure'> & { clipStyle: ClippedImageStyle; uniqueKey: string }) {
  const rootClassName = classnames('clipped-img', clipStyle, className);
  return (
    <figure className={rootClassName} {...rest}>
      <div className="container">
        <PlcImgLoader height={600} uniqueKey={uniqueKey} width={600} withSun={false} />
      </div>
    </figure>
  );
}

export { ClippedImage, ClippedImageLoader };
