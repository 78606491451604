import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect } from 'react';

export function useLockBodyScroll(isHidden: boolean, targetElement: Element | HTMLElement | null) {
  useEffect(() => {
    if (targetElement) {
      const callback = isHidden ? disableBodyScroll : enableBodyScroll;
      callback(targetElement);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isHidden, targetElement]);
}
