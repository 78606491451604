import { PlcContentLoader, PlcRect } from './PlcContentLoader.component';
import React, { ComponentProps, FC } from 'react';

const PlcFlatRectLoader: FC<ComponentProps<typeof PlcContentLoader>> = ({ height, uniqueKey, width, ...rest }) => {
  return (
    <PlcContentLoader height={height} uniqueKey={uniqueKey} width={width} {...rest}>
      <PlcRect height={height} rx="0" ry="0" width={width} />
    </PlcContentLoader>
  );
};

export { PlcFlatRectLoader };
